




















  import {
    api
  } from "@/api";
import { ParamModule } from "@/store/modules/param";
  import {
    WechatModule
  } from "@/store/modules/wechat";
  import {
    Component,
    Vue
  } from "vue-property-decorator";

  @Component({
    name: 'remark-and-tag',
  })
  export default class RemarkAndTag extends Vue {
    private remark = ''
    private wechat = this.$route.query.wechat || ''
    private placeholderText: any = '请输入备注'
    private isClick = false
    private tagList: any = []
    private desc: string = ''
    private friendId: number = 0

    private get wxId(): number {
      return WechatModule.wxId
    }

    private mounted() {
      this.getFriendInfo()
    }

    /**
     * @func 获取标签以及备注相关信息
     */
    private async getFriendInfo() {
      try {
        const res: any = await api.getFriendDetail({
          robot_id: this.wxId,
          username: this.wechat,
          wechat_show_state: 0
        })
        this.remark = res.detail.friend_detail.remark || ''
        this.tagList = res.detail.tag_detail || []
        this.desc = res.detail.friend_detail.desc
        this.friendId = res.detail.friend_detail.id
      } catch (err) {
        console.log(err)
      }
    }

    private submit() {
      if (this.isClick) return
      this.isClick = true
      this.updateRemark()
    }

    /**
     * @func 更新备注
     */
    private async updateRemark() {
      try {
        await api.friendTask({
          robot: this.wxId,
          task: {
            task_time: parseInt(new Date().getTime() / 1000 + '', 10),
            task_friends: {
              task_type: 1,
              remark: {
                wx_id: this.wechat,
                remark: this.remark || ''
              }
            }
          }
        })
        this.updateDesc()

      } catch (err) {
        this.isClick = false
        console.log(err)
      }
    }

    /**
     * @func 更新描述
     */
    private async updateDesc() {
      try {
        await api.friendTask({
          robot: this.wxId,
          task: {
            task_time: parseInt(new Date().getTime() / 1000 + '', 10),
            task_friends: {
              task_type: 12,
              update_desc: {
                wxid: this.wechat,
                desc: this.desc || ''
              }
            }
          }
        })
        this.$weui.topTips('设置成功', {
          className: 'success-tips'
        })
        this.isClick = false
        this.$router.go(-1)
      } catch (err) {
        this.isClick = false
        console.log(err)
      }
    }

    /**
     * @func 跳转到设置标签页面
     */
    private toSetTag() {
      ParamModule.changeFriendTag(JSON.stringify(this.tagList))
      this.$router.push({
        name: 'SetTag',
        query: {
          friendId: this.friendId + ''
        }
      })
    }
  }
